import { ColumnFilter, ColumnSort, PaginationState, Updater } from '@tanstack/react-table';

import type { SetFiltersParams } from '../../../common/hooks/useTableFilters';

import { useSyncPaginationWithSearchParams } from '../../../common/hooks/useSyncPagingWithSearchParams';
import { useSyncSortWithSearchParams } from '../../../common/hooks/useSyncSortWithSearchParams';
import { useTableFilters } from '../../../common/hooks/useTableFilters';

const DEFAULT_PAGINATION = { pageIndex: 0, pageSize: 10 };

const PRICING_PLAN_PARAM_FILTERS = {
  itemCode: { type: 'number' },
  subItemCode: { type: 'string' },
  itemDescription: { type: 'string' },
  artItemDescription: { type: 'string' },
  actionCode: { type: 'string' },
  severityCode: { type: 'string' },
  sequenceNumber: { type: 'number' },
  usd_partCost: { type: 'number' },
  usd_partDollars: { type: 'number' },
  partHours: { type: 'number' },
  repairHours: { type: 'number' },
  usd_repairDollars: { type: 'number' },
  paintHours: { type: 'number' },
  usd_paintDollars: { type: 'number' },
} as const;

type UseSyncPricingPlanTableWithSearchParams = Readonly<{
  setPagination: (updaterOrValue: Updater<PaginationState>) => void;
  setSorting: (value: ((prevState: ColumnSort[]) => ColumnSort[]) | ColumnSort[]) => void;
  toggleFilter: () => void;
  pagination: PaginationState;
  setFilters: (params: SetFiltersParams) => void;
  sorting: ColumnSort[];
  isFilter: boolean;
  closeFilters: () => void;
  filters: ColumnFilter[];
  debouncedFilters: ColumnFilter[];
}>;

export const useSyncPricingPlanTableWithSearchParams: () => UseSyncPricingPlanTableWithSearchParams = () => {
  const { sorting, setSorting } = useSyncSortWithSearchParams();
  const { pagination, setPagination } = useSyncPaginationWithSearchParams();

  const { isFilter, filters, setFilters, closeFilters, toggleFilter, debouncedFilters } = useTableFilters({
    onChange: () => {
      setPagination(DEFAULT_PAGINATION);
    },
    paramsDefinition: PRICING_PLAN_PARAM_FILTERS,
  });

  return {
    sorting,
    setSorting,
    isFilter,
    setFilters,
    filters,
    toggleFilter,
    closeFilters,
    setPagination,
    pagination,
    debouncedFilters,
  };
};
