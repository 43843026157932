import { QueryFunction, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { useDebounceValue } from '../../../../../../common/hooks/useDebounceValue';
import { fetchModelPlans } from '../../../../../../common/queries/modelPlans/modelPlans.api';
import { QueryKey } from '../../../../../../constants/queries';
import { OptionsType } from '../../../../../../types/field';

export interface UseModelPlanListSearchConfig {
  setSearchModelPlanInput: (str: string) => void;
  searchModelPlanInput: string;
  searchModelPlanOptions: OptionsType[];
  isSearchModelPlanFetching: boolean;
}

export const useModelPlanListSearch = (): UseModelPlanListSearchConfig => {
  const [searchModelPlanInput, setSearchInput] = useState<string>('');
  const [isSearchModelPlanFetching, setSearchModelPlanIsFetching] = useState<boolean>(false);
  const debouncedSearch = useDebounceValue(searchModelPlanInput);
  const queryClient = useQueryClient();

  const queryFn: QueryFunction<OptionsType[]> = async ({ signal }): Promise<OptionsType[]> => {
    if (searchModelPlanInput && isSearchModelPlanFetching) {
      await queryClient.cancelQueries([QueryKey.FETCH_MODEL_PLANS]);
    }

    const response = await fetchModelPlans({
      searchTerm: debouncedSearch,
      offset: 0,
      limit: 100,
      signal,
    });

    const items = response.items;

    setSearchModelPlanIsFetching(false);
    return items.map(({ planId, planDescription }) => ({
      label: `${planId} - ${planDescription}`,
      value: planId,
    }));
  };

  const { data, isFetching } = useQuery<OptionsType[]>({
    queryKey: [QueryKey.FETCH_MODEL_PLANS, debouncedSearch],
    queryFn,
    cacheTime: 0,
    enabled: Boolean(debouncedSearch),
  });
  const options = data && !isFetching ? data : [];
  return {
    setSearchModelPlanInput: (str: string): void => {
      if (str) {
        setSearchModelPlanIsFetching(true);
      }
      setSearchInput(str);
    },
    searchModelPlanInput,
    searchModelPlanOptions: options,
    isSearchModelPlanFetching: isSearchModelPlanFetching || isFetching,
  };
};
